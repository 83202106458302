<template>
  <section class="home">
    <!-- banner -->
    <div class="banner" id="index">
      <div class="swiper-container bannerSwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img class="blockImg pc-banner" src="../assets/images/pc-banner.png" />
            <img class="blockImg mobile-banner" src="../assets/images/mobile-banner.png" />
            <div class="pc-banner banner-btn-box">
              <el-button class="applyBtn" size="small" style="color: #1283ff" @click="$router.push({ path: '/apply' })" />
              <el-button class="loginBtn" size="small" style="color: #fff; background-color: transparent" @click="toCis">登录</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="bannerPoint"></div>
    </div>

    <div class="item1 paddingTop4" style="background: #fafafa">
      <ul class="autowidth flex">
        <li v-for="(item, index) in item1List" :key="index" @click="item.title === '支持新医保' && $router.push({ path: '/insurance' })">
          <img :src="item.imgLink" alt="" />
          <div>
            <h3 :style="{ color: item.color }">{{ item.title }}</h3>
            <p>{{ item.introduce }}</p>
          </div>
        </li>
      </ul>
    </div>

    <div class="paddingTop4 index-product" id="product" style="background: #fafafa">
      <!-- 产品介绍 -->
      <div class="paddingTop4 paddingBot4" style="background: #fff">
        <div class="autowidth">
          <div class="publicTit">
            <div class="font30 tit color444 fontBold">产品介绍</div>
            <span class="hLine bglogo"></span>
            <p class="color4A4949 pt20 font24 fontBold">为诊所提供信息化、数字化、智能化一站式解决方案</p>
          </div>
          <div class="productDL">
            <p class="font20 color676767 txtLeft">
              同云诊所服务平台系湖南同云医疗科技有限公司旗下的诊所服务品牌，以“首诊问诊、轻症问诊、慢病问诊”为定位，为诊所提供数字化一站式解决方案，让诊所在短时间内提升数字化管理水平，逐步实现智慧医疗的信息化管理和高标准化诊疗。
            </p>
            <el-button style="margin: 1% auto" @click="$router.push({ path: '/details' })">查看详情 ></el-button>
            <ul>
              <li v-for="item in 3" :key="item" @mouseenter="onEnter" @mouseleave="onLeave"></li>
            </ul>
          </div>
        </div>
      </div>

      <!-- 产品核心功能 -->
      <div class="functionMain coverBg paddingTop4">
        <div class="autowidth">
          <div class="publicTit">
            <div class="font30 tit color444 fontBold">产品核心功能</div>
            <span class="hLine bglogo"></span>
          </div>
          <div class="functionDL">
            <dl
              class="marginBot1 paddingBot1"
              v-for="(item, index) in functionList"
              :key="index"
              @mouseenter.capture="onEnter"
              @mouseleave.capture="onLeave"
            >
              <dd>
                <div class="icon">
                  <i class="iconfont" :class="item.icon"></i>
                </div>
              </dd>
              <dt>
                <h3 class="font24 txt">{{ item.title }}</h3>
                <p class="color666 font14 cot txtLeft">{{ item.introduce }}</p>
              </dt>
            </dl>
          </div>
        </div>
      </div>
    </div>

    <!-- 产品市场覆盖 -->
    <!-- <div class="paddingTop4" id="market" style="background: #fafafa">
      <div class="paddingTop4" style="background: #fff">
        <div class="autowidth">
          <div class="publicTit">
            <div class="font30 tit color444 fontBold">产品市场覆盖</div>
            <span class="hLine bglogo"></span>
          </div>
          <div class="mapDL" id="echartMap"></div>
        </div>
      </div>
    </div> -->

    <div class="paddingTopHeader" id="about">
      <Footer />
    </div>

    <Sidebar></Sidebar>
  </section>
</template>

<script>
// import * as echarts from 'echarts'
import chinaMap from '../assets/json/china.json'

import Sidebar from '@/components/Sidebar.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'Home',
  components: {
    Sidebar,
    Footer
  },
  data() {
    return {
      item1List: [
        {
          imgLink: require('@/assets/images/homeItem1Img1.png'),
          title: '支持新医保',
          introduce: '无缝对接新医保系统，可以在各个省区实现多重刷卡',
          color: '#14bf8d'
        },
        {
          imgLink: require('@/assets/images/homeItem1Img2.png'),
          title: '药品中心库',
          introduce: '拥有庞大的药品中心库，实现诊所药品一键管理，医保药品自动贯标',
          color: '#067cfe'
        },
        {
          imgLink: require('@/assets/images/homeItem1Img3.png'),
          title: '数字化接诊',
          introduce: '方便简洁的接诊系统，实现医生快速化、精准化工作，提升诊所的综合竞争力',
          color: '#f48a06'
        },
        {
          imgLink: require('@/assets/images/homeItem1Img4.png'),
          title: '小程序盘库',
          introduce: '微信小程序扫码盘库，一键入库，节省诊所的人力成本和设备成本',
          color: ' #e11212'
        }
      ],
      functionList: [
        {
          icon: 'icon-yuyueguahao',
          title: '预约挂号',
          introduce: '满足多场景下患者预约挂号的需求，患者可随时预约，诊所可及时处理预约信息；'
        },
        {
          icon: 'icon-shouye',
          title: '在线问诊',
          introduce:
            '智能化的病例、处方录入，极大的缩短了医生的诊断时间，提高了诊疗效率；电子病例为同一患者下一次的就诊提供了在线的档案记录，为医生快速复诊、诊断提供参考记录；'
        },
        {
          icon: 'icon-ico_yaofangguanli_tiaoborufang',
          title: '药房管理',
          introduce:
            '完善的药房管理功能，产品的进销存，一键入库，库存盘点，库存预警，供应商的管理等诸多药房功能，为诊所提供更为便捷的药品管理；'
        },
        {
          icon: 'icon-huanzheguanli',
          title: '患者管理',
          introduce: 'CRM会员系统，打通诊所与患者的沟通屏障，可以点对点的了解患者的病情，及时给自己的会员提供诊所资讯或者活动信息；'
        },
        {
          icon: 'icon-yibaoguanli',
          title: '医保对接',
          introduce: '已实现多地区新医保接口调试，可以实现医保刷卡，医保结算、慢特病管理等诸多医保功能；'
        },
        {
          icon: 'icon-shoufeiguanli',
          title: '收费管理',
          introduce: '后台支持多种收费模式，同时也能对诊所的销售情况做数据分析；'
        },
        {
          icon: 'icon-jingyingguanli',
          title: '经营管理',
          introduce:
            '全景检测经营动态，药品库存预警，经营状况，业务动态检测；诊所经营动态一目了然，及时分析重点指标，发现经营漏洞促进业务增长；'
        },
        {
          icon: 'icon-mendian-mendianshezhi-wodezhensuo',
          title: '线上商城',
          introduce: '同安易购电商平台，品类齐全，诸多品牌厂家强势入驻，为诊所提供线上快速选药的窗口；'
        },
        {
          icon: 'icon-ketang',
          title: '同云大讲堂',
          introduce: '定期分享诸多行业的知识，名家讲座，有价值的附加板块提供更多的经营板块，为诊所增加经营领域和经营方法；'
        }
      ],
      option: {
        geo: {
          map: 'china'
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      document.addEventListener('scroll', this.scrollResult, true)
      // this.init()
    })
  },
  methods: {
    scrollResult() {
      let navNode = document.querySelector('#nav')
      let top = document.body.scrollTop || document.documentElement.scrollTop
      if (top > 80) {
        navNode.classList.add('white-nav')
      } else {
        navNode.classList.remove('white-nav')
      }
    },
    onEnter(e) {
      e.target.classList.add('on')
    },
    onLeave(e) {
      e.target.classList.remove('on')
    },
    // init() {
    //   echarts.registerMap('china', chinaMap)
    //   let myChart = echarts.init(document.getElementById('echartMap'))
    //   myChart.setOption({
    //     visualMap: {
    //       left: '10%',
    //       bottom: '30%',
    //       orient: 'horizontal',
    //       min: 0,
    //       max: 16000,
    //       showLabel: false,
    //       show: false,
    //       inRange: {
    //         color: ['#cdebfe', '#0f9af1']
    //       }
    //     },
    //     series: [
    //       {
    //         type: 'map',
    //         map: 'china',
    //         itemStyle: {
    //           areaColor: '#cdebfe',
    //           borderColor: '#99A9A5'
    //         },
    //         emphasis: {
    //           label: {
    //             show: true
    //           },
    //           itemStyle: {
    //             areaColor: '',
    //             shadowBlur: 20,
    //             shadowOffsetX: 0,
    //             shadowColor: 'rgba(0, 0, 0, 0.5)'
    //           }
    //         },
    //         zoom: 1.2,
    //         data: [
    //           {
    //             name: '湖南省',
    //             value: 15052
    //           },
    //           {
    //             name: '湖北省',
    //             value: 10423
    //           },
    //           {
    //             name: '江西省',
    //             value: 10238
    //           },
    //           {
    //             name: '广西壮族自治区',
    //             value: 7183
    //           },
    //           {
    //             name: '安徽省',
    //             value: 3437
    //           },
    //           {
    //             name: '广东省',
    //             value: 3785
    //           },
    //           {
    //             name: '河南省',
    //             value: 9019
    //           },
    //           {
    //             name: '福建省',
    //             value: 2950
    //           },
    //           {
    //             name: '贵州省',
    //             value: 1287
    //           },
    //           {
    //             name: '重庆市',
    //             value: 2492
    //           },
    //           {
    //             name: '云南省',
    //             value: 1230
    //           },
    //           {
    //             name: '陕西省',
    //             value: 888
    //           },
    //           {
    //             name: '山西省',
    //             value: 999
    //           },
    //           {
    //             name: '山东省',
    //             value: 222
    //           },
    //           {
    //             name: '辽宁省',
    //             value: 333
    //           },
    //           {
    //             name: '吉林省',
    //             value: 111
    //           },
    //           {
    //             name: '北京省',
    //             value: 45
    //           },
    //           {
    //             name: '天津省',
    //             value: 78
    //           },
    //           {
    //             name: '浙江省',
    //             value: 1089
    //           },
    //           {
    //             name: '河北省',
    //             value: 2057
    //           },
    //           {
    //             name: '江苏省',
    //             value: 3089
    //           },
    //           {
    //             name: '西藏自治区',
    //             value: 3
    //           },
    //           {
    //             name: '新疆维吾尔自治区',
    //             value: 8
    //           }
    //         ]
    //       }
    //     ]
    //   })
    // },
    toCis() {
      window.open('https://cis.tyzsls.com', '_blank')
    }
  },
  destroyed() {
    document.removeEventListener('scroll', this.scrollResult, true)
  }
}
</script>

<style lang="scss" scoped>
.publicTit {
  text-align: center;
  padding-bottom: 3%;
  .tit {
    line-height: 1;
  }
}

.banner {
  position: relative;
  .swiper-slide {
    overflow: hidden;
  }
  .bannerPoint {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2.8%;
    text-align: center;
    z-index: 3;
    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      margin: 0 8px;
      background: #eee;
      opacity: 1;
    }
  }
  .swiper-pagination-bullet-active {
    background: #aaa;
  }
  .mobile-banner {
    display: none;
  }
  .banner-btn-box {
    position: absolute;
    left: 22%;
    top: 66%;
    display: flex;
    align-items: center;
  }
  .applyBtn {
    padding: 0;
    width: 235px;
    height: 118px;
    background: url('../assets/images/applyBtn.gif') no-repeat;
    background-size: 100% 100%;
    border: none;
  }
  .loginBtn {
    width: 134px;
    height: 48px;
    border: 2px solid #ffffff;
    border-radius: 10px;
    font-size: 24px;
    margin-top: -5px;
    margin-left: 20px;
    background-color: #fff !important;
    color: #3895fb !important;
  }
}
.item1 {
  ul {
    display: flex;
    justify-content: center;
  }
  li {
    width: 24%;
    height: auto;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 0px 13px 0px rgba(183, 183, 183, 0.35);
    border-radius: 5px;
    text-align: center;
    padding: 5% 35px;
    box-sizing: border-box;
    margin-right: 1%;
  }
  li:nth-of-type(1) {
    cursor: pointer;
  }
  li img {
    display: block;
    width: 115px;
    margin: 0 auto 10%;
  }
  li h3 {
    font-size: 24px;
  }
  li p {
    text-align: left;
    font-size: 16px;
    color: #595a5a;
    line-height: 30px;
  }
}
/* 产品介绍 */
.productDL {
  p {
    text-indent: 2em;
  }
  .aButton {
    display: inline-block;
    width: 176px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #bbbbbb;
    color: #7e7e7e;
  }
  ul {
    display: flex;
  }
  ul li {
    width: 400px;
    height: 400px;
    background-position: center;
    background-size: cover;
  }
  ul li:nth-of-type(1) {
    background-image: url('../assets/images/product1.png');
  }
  ul li:nth-of-type(2) {
    background-image: url('../assets/images/product2.png');
  }
  ul li:nth-of-type(3) {
    background-image: url('../assets/images/product3.png');
  }
  ul li:nth-of-type(1).on {
    background-image: url('../assets/images/productH1.png');
  }
  ul li:nth-of-type(2).on {
    background-image: url('../assets/images/productH2.png');
  }
  ul li:nth-of-type(3).on {
    background-image: url('../assets/images/productH3.png');
  }
}

/* 产品核心功能 */
.functionMain {
  background: #fafafa;
}
.functionDL {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .iconfont {
    font-size: 34px;
  }
  dl {
    width: 30%;
    background: #ffffff;
    border: 1px solid #eeeeee;
    text-align: center;
    cursor: pointer;
  }
  dd .icon {
    padding-top: 36px;
  }
  dt {
    padding: 0 20px;
  }
  dl.on {
    border: 1px solid #4496f8;
  }
  dl.on .txt {
    color: #4496f8;
  }
  dl.on .iconfont {
    color: #4496f8;
  }
  dl.on dt p {
    color: #4496f8;
  }
}
.mapDL {
  width: 80%;
  height: 500px;
  margin: 0 auto;
}

#about {
  background: #fafafa;
}

@media only screen and (max-width: 640px) {
  .item1 li {
    width: 49%;
    margin-bottom: 20px;
  }
  .item1 li img {
    width: 70px;
  }
  .item1 li h3 {
    font-size: 20px;
  }
  .item1 li p {
    font-size: 15px;
  }

  .functionDL dl {
    width: 48%;
  }

  .mapDL {
    width: 100%;
    height: 400px;
  }
}
@media only screen and (max-width: 480px) {
  .banner .pc-banner {
    display: none;
  }
  .banner .mobile-banner {
    display: block;
  }

  .item1 li {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    padding: 5%;
    margin-bottom: 20px;
  }
  .item1 li:last-child {
    margin-bottom: 0;
  }
  .item1 li img {
    margin: 0;
    margin-right: 3%;
  }
  .item1 li h3 {
    font-size: 18px;
    font-weight: bold;
  }

  .productDL ul li {
    width: 33%;
    height: 120px;
  }
  .productDL ul img {
    width: 100%;
  }

  .functionDL {
    dl {
      width: 100%;
      margin-bottom: 0;
      border: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      padding: 3% 2%;
      background: #fafafa;
      .icon {
        padding: 0;
      }
      .iconfont {
        color: #3895fb;
      }

      dt {
        padding: 0;
        margin-left: 3%;
        text-align: left;
      }
    }
    dl.on {
      border: none;
    }
  }
}
</style>
