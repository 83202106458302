<template>
  <ul class="Sidebar">
    <li @click="$router.push({ path: '/apply' })">
      <i class="iconfont icon-diannao"></i>
      <p>免费试用</p>
    </li>
    <li @click="toCis">
      <i class="iconfont icon-denglutongji"></i>
      <p>立即登录</p>
    </li>
    <li class="online-communicate-wrapper" @click="callPhone('13677304004')">
      <i class="iconfont icon-dianhua3"></i>
      <p>电话咨询</p>
      <div class="phone-call">13677304004/0731-81876200</div>
    </li>
    <li class="topLi">
      <el-backtop ref="backtop" :visibility-height="0">
        <i class="el-icon-arrow-up"></i>
      </el-backtop>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Sidebar',
  data() {
    return {}
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.backtop.visible = true
    })
  },
  methods: {
    toCis() {
      window.open('https://cis.tyzsls.com', '_blank')
    },
    // 调用拨号功能
    callPhone(phoneNumber) {
      window.location.href = 'tel://' + phoneNumber
    }
  }
}
</script>

<style lang="scss" scoped>
.Sidebar {
  position: fixed;
  right: 0;
  bottom: 15%;

  li {
    width: 70px;
    height: 70px;
    background: #3895fb;
    border-bottom: 1px solid #fff;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    p {
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
    }
    .el-backtop {
      position: inherit;
      margin: 15px auto;
    }
  }
  .topLi {
    display: flex;
    align-items: center;
    justify-content: center;
    p,
    .el-backtop {
      width: 33px;
      height: 33px;
      border-radius: 50%;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        color: #3895fb;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  .iconfont {
    color: #fff;
    font-size: 24px;
  }
  .phone-call {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    height: 66px;
    background: #3895fb;
    // box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 47%);
    border-radius: 3px;
    color: #ffffff;
    font-size: 20px;
    position: absolute;
    right: 76px;
    top: 150px;
    visibility: hidden;
    opacity: 1;
    transform-origin: center;
    transition: all 0.3s ease-in;
    white-space: nowrap;
  }
  .online-communicate-wrapper:hover .phone-call {
    visibility: visible;
    opacity: 1;
  }
}
@media only screen and (max-width: 480px) {
  .Sidebar {
    display: none;
  }
}
</style>
